import React from 'react';
import { IoIosVolumeHigh, IoIosVolumeOff } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../../analytics';
import { createUser } from '../../../../clientSideServices/users';
import { rogerDubuisSgBoutiqueId } from '../../../../config';
import { useTranslation } from '../../../../i18n';
import { IAccessRequest } from '../../../../interfaces';
import { actionAccessRequest } from '../../../../redux/actions';
import { actionUpdateUserAttributesAsync } from '../../../../redux/asyncActions';
import {
  ALLOW_ENTER_TUNNEL,
  DID_CLICK_FORM_SUBMIT_BUTTON,
  DID_CLOSE_FORM,
  DID_FAILED_TO_SEND_ACCESS_FORM,
  DID_INPUT_FORM_FIELD,
  DID_MUTE_ACCESS_FORM_VIDEO,
  DID_OPEN_FORM,
  DID_SEND_ACCESS_FORM,
  DID_UNMUTE_ACCESS_FORM_VIDEO
} from '../../../../utils/constants';
import { isUserOnMobile } from '../../../../utils/deviceDetector';
import { getIdentityId } from '../../../../utils/identity';
import PrivacyPolicy from '../../../Legal/PrivacyPolicy';
import TermsOfUse from '../../../Legal/TermsOfUse';
import { isValidEmail, isValidName } from '../../Appointment/inputValidator';
import { registerAccessForm } from '../../Appointment/registrationService';

interface IAccessRequestPristine {
  name: boolean;
  email: boolean;
  acceptedTermsOfUse: boolean;
}

export interface IAccessRequestData {
  name: string | null;
  email: string | null;
  acceptedTermsOfUse: boolean;
  subscribeNews: boolean;
}

enum EmailSentState {
  INITIAL = 'ENTER',
  SENDING = 'ENTERING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

const RequestAccessForm = ({
  accessRequest
}: {
  accessRequest: IAccessRequest;
}) => {
  const dispatch = useDispatch();
  const [videoMuted, setVideoMuted] = React.useState(true);
  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );
  const [pristine, setPristine] = React.useState<IAccessRequestPristine>({
    name: true,
    email: true,
    acceptedTermsOfUse: true
  });

  const [formData, setFormData] = React.useState<IAccessRequestData>({
    name: undefined,
    email: undefined,
    acceptedTermsOfUse: false,
    subscribeNews: false
  });

  const mandatoryFields: (keyof IAccessRequestData)[] = [
    'name',
    'email',
    'acceptedTermsOfUse'
  ];

  const animationDuration = 300;
  const handleClose = () => {
    dispatch(actionAccessRequest({ ...accessRequest, open: false }));
    logEvent(DID_CLOSE_FORM, DID_CLOSE_FORM, {
      form: 'RequestAccessForm'
    });
  };

  const handleName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, name: false });
    setFormData({ ...formData, name: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'name',
      form: 'RequestAccessForm'
    });
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    setFormData({ ...formData, email: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'email',
      form: 'RequestAccessForm'
    });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'termsAndConditions',
      form: 'RequestAccessForm',
      checked: value
    });
  };

  const handleNewsletterSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, subscribeNews: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'newsletter',
      form: 'RequestAccessForm',
      checked: value
    });
  };

  const removePristine = () => {
    const nonPristine: IAccessRequestPristine = {
      name: false,
      email: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  React.useEffect(() => {
    setVideoMuted(isUserOnMobile() || accessRequest?.muteVideo);
    if (accessRequest?.open) {
      logEvent(DID_OPEN_FORM, DID_OPEN_FORM, {
        form: 'RequestAccessForm'
      });
    }
  }, [accessRequest]);

  const handleSend = () => {
    removePristine();
    logEvent(DID_CLICK_FORM_SUBMIT_BUTTON, DID_CLICK_FORM_SUBMIT_BUTTON, {
      form: 'RequestAccessForm'
    });
    if (mandatoryFields.some((field) => !formData[field])) {
      return;
    }
    if (!isValidEmail(formData.email) || !isValidName(formData.name)) {
      return;
    }
    setEmailSentState(EmailSentState.SENDING);

    registerAccessForm(formData, rogerDubuisSgBoutiqueId)
      .then(() => {
        window[ALLOW_ENTER_TUNNEL] = true;
        dispatch(actionAccessRequest({ open: false, granted: true }));
        logEvent(DID_SEND_ACCESS_FORM, DID_SEND_ACCESS_FORM);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAILED_TO_SEND_ACCESS_FORM,
          DID_FAILED_TO_SEND_ACCESS_FORM,
          { error }
        );
      })
      .finally(() => {
        const user = createUser(getIdentityId(), formData.name, formData.email);
        dispatch(
          actionUpdateUserAttributesAsync(user.id, {
            alias: user.alias,
            email: user.email
          })
        );
      });
  };

  const { t } = useTranslation();

  return (
    <div className="appointment request">
      <Transition
        in={accessRequest?.open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div className={`popup-container fadein ${state}`} id="ap-popup">
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img
                  src="https://storiez-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/4bba131d-6238-4c54-b4e4-506bc199d13d/logo-light.png"
                  alt="ROGER DUBUIS"
                />
              </div>
              <form id="emailform">
                <div className="heading">
                  <h2>
                    Discover Roger Dubuis' world <br />
                    and visit virtually the Manufacture now
                  </h2>
                  <p>An exciting way to experience Hyper Horology</p>
                </div>

                <div className="video-container">
                  <video
                    autoPlay
                    loop
                    playsInline
                    muted={videoMuted}
                    src="https://panos.inspify.io/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/request_access2.mp4"
                  ></video>

                  {videoMuted ? (
                    <IoIosVolumeOff
                      onClick={() => {
                        setVideoMuted(false);
                        logEvent(
                          DID_UNMUTE_ACCESS_FORM_VIDEO,
                          DID_UNMUTE_ACCESS_FORM_VIDEO
                        );
                      }}
                    />
                  ) : (
                    <IoIosVolumeHigh
                      onClick={() => {
                        setVideoMuted(true);
                        logEvent(
                          DID_MUTE_ACCESS_FORM_VIDEO,
                          DID_MUTE_ACCESS_FORM_VIDEO
                        );
                      }}
                    />
                  )}
                </div>

                <div className="two-col">
                  <div className="input-group">
                    <label>{t('vb:name')}:</label>
                    <input
                      type="text"
                      id="inputname"
                      name="name"
                      placeholder={t('vb:enter_your_name')}
                      onBlur={handleName}
                    />
                    {!pristine.name && !isValidName(formData.name) && (
                      <span className="error">
                        {t('vb:please_enter_valid_name')}
                      </span>
                    )}
                  </div>
                  <div className="input-group">
                    <label>{t('email')}:</label>
                    <input
                      type="email"
                      id="inputemail"
                      name="email"
                      placeholder={t('vb:enter_your_email')}
                      onBlur={handleEmail}
                    />
                    {!pristine.email && !isValidEmail(formData.email) && (
                      <span className="error">
                        {t('vb:please_enter_valid_email')}
                      </span>
                    )}
                  </div>
                </div>

                <div className="input-group tstc">
                  <label style={{ position: 'relative' }}>
                    <input type="checkbox" id="inputtstc" onChange={handleTC} />
                    {!pristine.acceptedTermsOfUse &&
                      !formData.acceptedTermsOfUse && (
                        <span className="error" style={{ top: '-8px' }}>
                          {t('vb:please_accept_tc')}
                        </span>
                      )}
                    {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
                    <PrivacyPolicy />
                    {t('vb:tc_part_three')}
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      id="inputsubscribe"
                      onChange={handleNewsletterSubscription}
                    />
                    I would like to receive marketing information about Roger
                    Dubuis' products or services. We may send you this
                    information using e-mail, text, telephone, post, social
                    media or through online advertising. You can ask us to stop
                    marketing at any time. For further information about how we
                    use your personal information, please see our{' '}
                    <a
                      href="https://www.rogerdubuis.com/en/privacy"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </label>
                </div>

                <div className="input-group">
                  <button
                    id="sendform"
                    type="button"
                    onClick={() => handleSend()}
                  >
                    {t(`vb:${emailSentState}`)}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>
        {`
          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }

          .request .error {
            color: #ffa8a8;
          }

          .sub-tc {
            display: block;
            margin-left: 1em;
            text-indent: -0.6em;
            padding-left: 1em;
            margin-top: 8px;
          }
          .request .form-container {
            background: #000;
            color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 50px rgba(165, 31, 37, 0.7);
            padding: 30px 50px 100px;
          }
          .heading {
            text-align: center;
            font-size: 16px;
          }
          .heading h2 {
            font-weight: normal;
            font-size: 2.5em;
            letter-spacing: normal;
            line-height: 1.2;
          }
          .heading p {
            font-size: 1.4em;
            letter-spacing: 1px;
          }
          .tstc label {
            color: #ccc;
            padding-top: 10px;
          }

          #sendform {
            background: #fff;
            color: #000;
            border-radius: 5px;
            width: 200px;
            margin: auto;
          }

          .request input {
            color: #fff;
          }
          video {
            width: 100%;
            height: auto;
          }

          .request .form-container::-webkit-scrollbar {
            width: 5px;
          }

          .request .form-container::-webkit-scrollbar-track {
            box-shadow: inset 0 0 20px #222;
          }

          .request .form-container::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 20px #555;
          }

          .video-container {
            margin-left: -50px;
            margin-right: -50px;
            position: relative;
          }

          .video-container :global(svg) {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 30px;
            height: auto;
            cursor: pointer;
          }

          .request .logo {
            margin-bottom: 40px;
          }

          @media (max-width: 768px) {
            .tstc {
              margin-top: 10px;
            }
            .heading {
              font-size: 10px;
            }
            .mobile {
              display: inline-block;
            }

            .full {
              display: none;
            }
            #sendform {
              margin-top: 30px;
            }
            .video-container {
              margin-left: -20px;
              margin-right: -20px;
            }
            .request .form-container {
              padding: 20px 20px 100px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default RequestAccessForm;
