import { Col, Row } from 'react-bootstrap';
import { IProduct, Source } from '../../interfaces';
import { AppContext } from '../AppContext';
import React from 'react';

const BrandHeader = ({
  product,
  description,
  showLogo
}: {
  product?: IProduct;
  description?: string;
  showLogo?: boolean;
}) => {
  const context = React.useContext(AppContext);
  const isFromShowroom = context.source === Source.Showroom;
  if (isFromShowroom && !description) return null;

  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center">
        {showLogo && (
          <img
            className="showcase-logo"
            src={`https://storiez-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/${product.brandId}/logo.png`}
            alt=""
          />
        )}
        {description && (
          <p className={`${isFromShowroom ? 'no-header' : ''}`}>
            {description}
          </p>
        )}
        <div className={`separator ${isFromShowroom ? 'no-header' : ''}`}></div>
        <style jsx>{`
          .separator {
            display: block;
            height: 1px;
            background-color: #cacaca;
            width: 100px;
            margin: 20px auto 60px;
          }

          .showcase-logo {
            height: 50px;
            width: auto;
            margin-top: 40px;
          }

          .separator.no-header {
            margin-bottom: 20px;
          }

          h1 {
            font-size: 1.4em;
            margin: 10px 0;
          }
          p {
            letter-spacing: 1px;
          }

          p.no-header {
            font-weight: 400;
            letter-spacing: 0.3px;
            margin-top: 20px;
          }

          @media (min-width: 768px) {
            .showcase-logo {
              height: 80px;
            }
            .separator {
              width: 200px;
              margin: 30px auto 90px;
            }
            .separator.no-header {
              margin: 50px auto 60px;
            }
            h1 {
              font-size: 2em;
              font-weight: 300;
            }
            p {
              padding-top: 10px;
            }

            p.no-header {
              font-size: 2em;
              font-weight: 300;
              letter-spacing: 1px;
              margin-top: 0;
            }
          }
        `}</style>
      </Col>
    </Row>
  );
};

export default BrandHeader;
